import { ref, watch, computed, onMounted, onBeforeUnmount, toValue } from 'vue'
import { useElementVisibility } from '@vueuse/core'

export function useVideoController (videoRef, previewVideoUrl, fullVideoUrl) {
  const videoVisible = useElementVisibility(videoRef)
  const isFullscreen = ref(false)

  const previewVideoLink = computed(() => toValue(previewVideoUrl))
  const fullVideoLink = computed(() => toValue(fullVideoUrl))
  //  to-do: userAgent composable [ENG-4739]
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  async function tryPlayVideo () {
    if (videoRef.value) {
      try {
        await videoRef.value.play()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Video play failed:', error)
      }
    }
  }

  async function tryEnterFullscreen (videoElement) {
    const requestFullscreen =
      videoElement.requestFullscreen ||
      videoElement.webkitRequestFullscreen ||
      videoElement.mozRequestFullScreen ||
      videoElement.msRequestFullscreen

    if (requestFullscreen) {
      try {
        await requestFullscreen.call(videoElement)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Fullscreen request failed:', error)
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn('Fullscreen API not supported on this browser/device.')
    }
  }

  async function onBeginFullscreen () {
    if (!videoRef.value) return

    videoRef.value.classList.remove('hero-video')
    videoRef.value.setAttribute('webkit-playsinline', 'false')
    videoRef.value.setAttribute('playsinline', 'false')
    videoRef.value.src = fullVideoLink.value
    videoRef.value.controls = true
    videoRef.value.muted = false
    videoRef.value.load()

    if (isIOS) {
      try {
        // For iOS, play video before requesting fullscreen
        await tryPlayVideo()
        const enterFullscreen = videoRef.value.webkitEnterFullscreen
        if (enterFullscreen) {
          enterFullscreen.call(videoRef.value)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error entering fullscreen on iOS:', error)
      }
    } else {
      try {
        await tryEnterFullscreen(videoRef.value)
        await tryPlayVideo()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error on entering fullscreen:', error)
      }
    }
  }

  function onEndFullscreen () {
    if (!videoRef.value) return

    videoRef.value.classList.add('hero-video')
    videoRef.value.src = previewVideoLink.value
    videoRef.value.muted = true
    videoRef.value.controls = false
    videoRef.value.load()
  }

  function isVideoInFullscreen () {
    return (
      document.fullscreenElement === videoRef.value ||
      document.webkitFullscreenElement === videoRef.value
    )
  }

  function updateFullscreenState () {
    isFullscreen.value = isVideoInFullscreen()
  }

  watch(videoVisible, (state) => {
    if (state && videoRef.value && (videoRef.value.paused || videoRef.value.ended)) {
      tryPlayVideo()
    }
  })

  watch(isFullscreen, (value) => {
    if (value) {
      onBeginFullscreen()
    } else {
      onEndFullscreen()
    }
  })

  onMounted(() => {
    document.addEventListener('fullscreenchange', updateFullscreenState)
    videoRef.value.addEventListener('webkitendfullscreen', onEndFullscreen)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('fullscreenchange', updateFullscreenState)
    videoRef.value.removeEventListener('webkitendfullscreen', onEndFullscreen)
  })

  return {
    videoRef,
    previewVideoLink,
    fullVideoLink,
    isFullscreen,
    onBeginFullscreen,
    onEndFullscreen,
    tryPlayVideo
  }
}
