const KLAVIYO_PUBLIC_KEY = 'MpJiX9'

export default {
  install (_app, _options) {
    function loadKlaviyo () {
      const id = 'klaviyo-script'
      if (document.getElementById(id)) {
        return
      }
      const script = document.createElement('script')
      script.id = id
      script.type = 'text/javascript'
      script.async = true
      script.src = `https://static.klaviyo.com/onsite/js/${KLAVIYO_PUBLIC_KEY}/klaviyo.js`
      document.head.appendChild(script)
    }
    loadKlaviyo()
  }
}
