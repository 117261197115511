import '@/vue2_compat.js'
import { isBackendProd, isBeta, isHostBeta, isHostStaging, isProd } from '@shared/utils/environment.js'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/homepage/App.vue'
import logger from '@/logger'
import router from '@/homepage/router.js'

import vClickOutside from 'click-outside-vue3'
import VueProgressiveImage from 'vue-progressive-image'
import 'vue-progressive-image/dist/style.css'
import { DisplaySizeMixin } from '@/homepage/components/common/DisplaySizeMixin.js'

import { browserTracingIntegration, init } from '@sentry/vue'
import { captureConsoleIntegration } from '@sentry/integrations'

import VueIntercom from '@/homepage/plugins/intercom.js'
import VueSegment from '@/plugins/segment.js'
import VueKlaviyo from '@/homepage/plugins/klaviyo.js'
import { createStore } from 'vuex'

const app = createApp(App)

// Frontend logging
app.use(logger)

// Sentry
if (isBackendProd || isHostStaging || isHostBeta) {
  init({
    app,
    environment: isProd ? 'production' : isBeta ? 'beta' : 'staging',
    dsn: 'https://4fe9443a238947fa8c28dc5d82df3850@sentry.io/1781864',
    integrations: [
      browserTracingIntegration({ router }),
      captureConsoleIntegration({ levels: ['error'] })
    ],
    tracesSampleRate: 0.75,
    release: `armoire-vue-app@${process.env.PACKAGE_VERSION}`
  })
}

app.use(VueProgressiveImage, { delay: 0 })

app.use(createPinia())
app.use(createStore()) // Empty Vuex store to suppress warnings.
app.use(router)

app.use(vClickOutside)
app.mixin(DisplaySizeMixin)

// Segment
app.use(VueSegment, {
  prod: isProd,
  beta: isBeta
})

// Intercom
// NOTE that Segment analytics for Staging use the DEV source and go to the TEST Intercom workspace
// Intercom initialization must match that
app.use(VueIntercom, {
  test: !isProd && !isBeta
})

app.use(VueKlaviyo) // Marketing Sign-up Forms

app.mount('#app')
