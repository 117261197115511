<template>
  <HeroImage ref="heroImage"/>
  <BenefitsBanner class="row"/>
  <RentingMadeEasySection ref="rentingMadeEasy"/>
  <CuratedCollections/>
  <FavoriteBrandsSection id="brands"/>
  <PersonalStylingSection/>
  <InTheNewsSection/>
  <PlansForEveryBudget id="plans"/>
  <WhatMembersSay/>
  <EmailCaptureSection/>
</template>

<script setup>
import { onActivated, ref, watch, computed, configureCompat } from 'vue'
import { useElementVisibility } from '@/composables/element'
import HeroImage from '@/homepage/components/revamp/HeroImage.vue'
import RentingMadeEasySection from '@/homepage/components/revamp/RentingMadeEasySection.vue'
import CuratedCollections from '@/homepage/components/CuratedCollections.vue'
import PlansForEveryBudget from '@/homepage/components/revamp/PlansForEveryBudget.vue'
import PersonalStylingSection from '@/homepage/components/revamp/PersonalStylingSection.vue'
import EmailCaptureSection from '@/homepage/components/revamp/EmailCaptureSection.vue'
import InTheNewsSection from '@/homepage/components/revamp/InTheNewsSection.vue'
import FavoriteBrandsSection from '@/homepage/components/revamp/FavoriteBrandsSection.vue'
import WhatMembersSay from '@/homepage/components/revamp/WhatMembersSay.vue'
import useAnalytics from '@shared/composables/analytics.js'
import { useHomepageStore } from '@/stores/homepage.js'
import BenefitsBanner from '../components/homepage/BenefitsBanner.vue'

configureCompat({ WATCH_ARRAY: false })

const { trackPageView } = useAnalytics()

onActivated(() => {
  trackPageView('Homepage')
})

const homepageStore = useHomepageStore()
const rentingMadeEasy = ref(null)
const heroImage = ref(null)
const rentingMadeEasyVisible = useElementVisibility(rentingMadeEasy)
const heroImageVisible = useElementVisibility(heroImage)
const elementsAreVisible = computed(() => rentingMadeEasyVisible.value || heroImageVisible.value)

watch(elementsAreVisible, (value) => {
  homepageStore.showMobileFooter = !value
})
</script>

<style scoped lang="scss">
#brands, #plans { @include anchor-link; }
// .heading3 is a soon be depraecated class,
// but we need to keep it for now to maintain BenefitBanner styles
:deep(.heading3) {
  font-size: 1.125rem !important;
  line-height: 1.5rem !important;
  font-family: Montserrat,Helvetica,sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: .2em !important;
}
</style>
