<template>
  <div
    class="referral-footer">
    <div class="footer-content">
      <div>
        <img :src="getImageUrl(`referral/icon-footer.svg`)">
      </div>
      <div>
        <h6
          class="header"
          :class="{'invisible': !client || !referralCampaign}">
          Accept {{ client && client.firstName }}'s invite + save up to {{ getRefereeDiscount(referralCampaign) }}%
        </h6>
        <h3 class="subheader">
          Take your style quiz and preview your closet
        </h3>
        <primary-button variant="inverse">
          <a :href="getStartUrl(referralCampaign)">
            Take your quiz
          </a>
        </primary-button>
      </div>
    </div>
  </div>
</template>

<script>
import PrimaryButton from '@/homepage/components/common/PrimaryButton.vue'
import { getRefereeDiscount } from '@/homepage/utils/referrals.js'
import { getImageUrl } from '@/homepage/utils/url.js'
import { useSignUpNavigationStore } from '@/stores/signUpNavigation.js'

export default {
  name: 'ReferralFooter',
  components: { PrimaryButton },
  props: {
    client: {
      type: Object,
      default: null
    },
    referralCampaign: {
      type: Object,
      default: null
    }
  },
  setup () {
    const { getStartUrl } = useSignUpNavigationStore()
    return {
      getStartUrl
    }
  },
  methods: {
    getImageUrl,
    getRefereeDiscount
  }
}
</script>

<style lang="scss" scoped>
  .referral-footer {
    display: flex;
    justify-content: center;
    padding: 48px 24px;
    background-color: $plum;
  }

  .header {
    color: $white;
    margin-bottom: 12px;
  }

  .subheader {
    margin: 0 0 36px;
    color: $white;
  }
</style>
