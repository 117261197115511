<template>
  <div
    class="row hero-section text-origami bg-plum text-center text-lg-start">
    <div class="col-12 col-lg-6 p-0 video-wrapper">
      <video
        id="HIW-video"
        ref="heroVideo"
        class="hero-video"
        autoplay
        loop
        playsinline
        alt="Rent an infinite closet, styled just for you. Armoire is a women's clothing rental membership that continuously styles you with curated, contemporary designs for every occasion."
        height="100%"
        width="100%"
        muted>
        <source
          :src="previewVideoLink"
          type="video/mp4">
      </video>
      <button
        class="play-button-wrapper bg-transparent border-0"
        label="Watch How It Works"
        role="button"
        alt="Play button for Watch How It Works"
        @keydown="onBeginFullscreen"
        @click="onBeginFullscreen">
        <SvgPlay
          class="play-button"
          :size="isTabletOrSmaller ? 40 : 90"/>
        <p
          class="text-white text-start text-uppercase"
          :class="isTabletOrSmaller ? 'mobile-btn-text' : 'heading3'">
          Watch How It Works
        </p>
      </button>
    </div>

    <div class="my-lg d-flex flex-column justify-content-center align-items-center align-items-lg-start col-12 col-lg-6">
      <div class="hero-content mx-lg-xxl">
        <h1 class="marketing hero-title text-capitalize text-origami mb-lg-md">
          {{ title ?? '&nbsp;' }}
        </h1>
        <p class="semi-bold mb-lg mb-lg-md hero-subtitle">
          {{ subtitle ?? '&nbsp;' }}
        </p>
        <GetStartedButton
          location="Hero Image"
          variant="secondary"
          fixed-width
          class="text-uppercase"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGenericContentStore } from '@shared/stores/genericContent.js'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import SvgPlay from '../common/SvgPlay.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import { useVideoController } from '@/homepage/composables/heroVideo.js'

const heroVideo = ref(null)
const {
  previewVideoLink,
  onBeginFullscreen
} = useVideoController(
  heroVideo,
  'https://d2wwsm0zoxn9fr.cloudfront.net/videos/homepage_video_preview_loop_desktop_v1_reduced.mp4',
  'https://d2wwsm0zoxn9fr.cloudfront.net/videos/HIW_Full_2022.mp4'
)
const { isTabletOrSmaller } = useScreenSize()

const { heroImageSection } = storeToRefs(useGenericContentStore())

const title = computed(() =>
  heroImageSection.value?.title ??
  document.querySelector('meta[name="armoire:hero_title"]')?.content
)
const subtitle = computed(() =>
  heroImageSection.value?.blocks[0]?.text ??
  document.querySelector('meta[name="armoire:hero_subtitle"]')?.content
)

</script>

<style lang="scss" scoped>
.hero-video {
  object-fit: cover;
  object-position: top left;
  @include media-tablet-or-smaller {
    max-height: 30dvh;
  }
}
.hero-content {
  @include media-tablet-or-smaller {
    padding: 0;
    max-width: 80%;
    margin: 0 auto;
  }
}
.hero-title {
  @include media-desktop {
    max-width: 425px;
  }
}
.video-wrapper {
 height: 30dvh;
  @include media-desktop {
    height: 40dvh;
    min-height: 425px;
    width: 47% !important;
  }
}
.play-button {
  margin-right: 15px;
}
.play-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: white;
  bottom: 92px;
  left: $spacing-lg;
  margin-bottom: -92px;
  p {
    text-shadow: $armor 1px 0 10px;
  }
  @include media-tablet-or-smaller {
    bottom: 50px;
    margin-bottom: -42px;
  }
}
.mobile-btn-text {
  font-family: Montserrat, Helvetica, sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 0.2em !important;
  font-size: 12px !important;
}
.hero-subtitle {
  max-width: 560px;
}
</style>
